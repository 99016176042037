<template>
  <div :class="isRTLPage ? 'right-align' : 'left-align'">
    <BreadCrumb type="area" :data="linkData" v-if="linkData" />
    <Banner :banner="bannerImages" />
    <div class="my-5 hotelplusflight">
      <div class="container">
        <div class="row" :class="{'flex-row-reverse': !isRTLPage}">
          <Sidebar :hotelName="hotelName" :destination="destinationCityName" :remarks="remarks" />
          <div class="col-md-9 filter-cont dVacation-pack" v-if="isLoading || hotels.length === 0">
            <ContentLoading type="product" />
          </div>
          <div class="col-md-9 filter-cont dVacation-pack" v-else>
            <div class="holiday-hotel-details">
              <h2>
                {{ destinationCityName }} - {{ hotelName }}
              </h2>
            </div>
            <div class="clearfix"></div>
            <BreadCrumb type="flat" :stage="bookingStage" />
            <ReadMore :message="description"/>
            <div class="facilitie-list">
              <Facilitie v-for="(item, index) in facilities" :key="index" :data="item.name"/>
            </div>

            <div class="clearfix"></div>
            <Slider :slides="imageList" v-if="this.imageList.length" :hotelName="hotelName"/>

            <div class="hotelplusflight_bannerarea">
              <div class="hotelplusflight_body">
                <h2>{{ hotelName }}</h2>
                <div class="d-flex retingbox">
                  <span class="d-inline-block"><a href="#" ><i class="fas fa-map-marker-alt"></i>&nbsp;&nbsp;{{ destinationCityName }}</a></span>
                </div>
                <p class="d-block dated"><i class="far fa-calendar-alt"></i>&nbsp;&nbsp;{{ betweenDateString }}</p>
                <p><i class="far fa-user"></i>&nbsp;&nbsp;{{ personString }}</p>
                <p><i class="fas fa-coffee"></i>&nbsp;&nbsp;{{ basisName }}</p>
                <p v-if="remark"><i class="far fa-comment-dots"></i>&nbsp;&nbsp;{{ remark }}</p>
              </div>
            </div>
            <CollapseList :data="collapseData" v-if="collapseData.length > 0"/>

            <div class="chooseroom_area">
              <PriceArea :hotels="hotels" :moreHotelInfo="moreHotelInfo"
              :searchGuid="searchGuid" :supplier="supplier" :hotelName="hotelName"
              :destinationCityName="destinationCityName"/>
            </div>

            <div class="price-composition-desktop">
              <RoomComposition :information="description" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer page="product" v-if="!hideFooter"/>
  </div>
</template>

<script>
import TemplateProductPage from './TemplateProductPage';

export default {
  mixins: [TemplateProductPage],
  components: {
    Banner: () => import('@/components/productPage/productBanner/ProductBannerTheme0'),
    BreadCrumb: () => import('@/components/productPage/Breadcrumb'),
    Sidebar: () => import('@/components/productPage/ProductSidebar'),
    ReadMore: () => import('@/components/productPage/atoms/Readmore'),
    Slider: () => import('@/components/productPage/productSlider/ProductSliderTheme0'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    PriceArea: () => import('@/components/productPage/productPriceArea/PriceAreaTheme0'),
    RoomComposition: () => import('@/components/productPage/RoomComposition'),
    CollapseList: () => import('@/components/productPage/CollapseList'),
    Facilitie: () => import('@/components/productPage/atoms/facility/FacilityTheme0'),
    Footer: () => import('@/components/content/footer/FooterTheme0'),
  },
};
</script>

<style>
.modal {
  background-color: transparent;
}
.b-rating .b-rating-star,
.b-rating .b-rating-value {
  padding: 0 0.1em;
}
</style>

<style scoped>
.left-align .hotelplusflight {
  text-align: left;
  direction: ltr;
}
.hotelplusflight {
  margin-top: 1em !important;
}
.retingbox .rating {
  height: auto;
  color: black;
  background: transparent;
  border: none;
  padding: 0;
  width: auto;
}
.clearfix {
  clear: both;
}
.hotelplusflight_body,
.mobail_part {
  display: none;
}
@media (max-width: 479px) {
  .holiday-hotel-details,
  .facilitie-list,
  .price-composition-desktop {
    display: none;
  }
  .hotelplusflight_body,
  .mobail_part {
    display: block;
  }
  .product-page-flight .filterItem {
    margin: 0;
  }
  .dVacation-pack {
    padding: 0;
  }
}
</style>
